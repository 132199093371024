import { Context } from '@nuxt/types'
import { getUserLocale, getVideoLocale } from '~/services/user-locale'

type UrlPageMap = Record<string, (ctx: Context) => void>

// Map of the Form {<url>: <function>, ...}
// If any of the <url>s is requested, its corresponding <function> will run (and potentially
// redirect to a different page) before the <url>'s page is displayed. This does not otherwise
// alter the page display, leading to a 404 unless redirected or unless the page is provided in
// another manner (e.g. provided in /pages and white-listed in appConfig.localeLessPageWhitelist).
// @ts-ignore
export default {
  '/': async (ctx): Promise<void> => {
    const { redirect, $vuex, $isFeatureEnabled, route, $routerI18n } = ctx
    const userSlug = await getUserLocale(ctx)
    $vuex.commit('INIT_LOCALE_SSR', userSlug)
    const { email, order, didBuyTrampoline } = route.query
    if (email && order && $isFeatureEnabled('video'))
      redirect(
        $routerI18n.localePath(
          {
            name: 'order-confirmation',
            query: { email, order, didBuyTrampoline },
          },
          userSlug
        )
      )
    else redirect($routerI18n.localePath('/', userSlug))
  },

  '/video-privacy-policy/': async (ctx): Promise<void> => {
    const { redirect, $vuex, $routerI18n } = ctx
    const userSlug = await getUserLocale(ctx)
    $vuex.commit('INIT_LOCALE_SSR', userSlug)

    // Umleiten mit der generierten URL
    redirect($routerI18n.localePath('/video-privacy-policy/', userSlug))
  },

  '/auth-sign-in/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    let userSlug = await getUserLocale(ctx)

    // Extrahieren des Sprachteils vor dem Bindestrich
    const [language, region] = userSlug.split('-')

    // Überprüfen, ob die Sprache Deutsch oder Englisch ist, sonst auf Englisch setzen
    userSlug = language === 'de' || language === 'en' ? language : 'en'

    // Zusammenfügen des neuen userSlug mit der Region
    userSlug += `-${region}`
    $vuex.commit('INIT_LOCALE_SSR', userSlug)
    const { email, step, pap, productID, customer, orderID, price, currency } =
      route.query

    // Basis-URL für den Redirect
    let redirectUrl = '/auth-sign-in/'

    // Erweiterte Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const queryParams = {
      email: getStringParam(email),
      step: getStringParam(step),
      pap: getStringParam(pap),
      productID: getStringParam(productID),
      customer: getStringParam(customer),
      orderID: getStringParam(orderID),
      price: getStringParam(price),
      currency: getStringParam(currency),
    }

    let firstParam = true // Hilfsvariable, um zu überprüfen, ob es das erste Query-Parameter ist

    // Durchlaufen der queryParams und Hinzufügen zur URL
    for (const [key, value] of Object.entries(queryParams)) {
      if (value) {
        redirectUrl += `${firstParam ? '?' : '&'}${key}=${encodeURIComponent(
          value
        )}`
        firstParam = false // Setzen Sie firstParam auf false, nachdem das erste gültige Parameter hinzugefügt wurde
      }
    }

    // Umleiten mit der generierten URL
    redirect($routerI18n.localePath(redirectUrl, userSlug))
  },

  '/auth-sign-up/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    let userSlug = await getUserLocale(ctx)

    // Extrahieren des Sprachteils vor dem Bindestrich
    const [language, region] = userSlug.split('-')

    // Überprüfen, ob die Sprache Deutsch oder Englisch ist, sonst auf Englisch setzen
    userSlug = language === 'de' || language === 'en' ? language : 'en'

    // Zusammenfügen des neuen userSlug mit der Region
    userSlug += `-${region}`
    $vuex.commit('INIT_LOCALE_SSR', userSlug)
    const { email, step, trial } = route.query

    // Basis-URL für den Redirect
    let redirectUrl = '/auth-sign-up/'

    // Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const emailParam = getStringParam(email)
    const stepParam = getStringParam(step)
    const trialParam = getStringParam(trial)

    // Prüfen, ob 'email' vorhanden und nicht leer ist
    if (emailParam) {
      redirectUrl += '?email=' + encodeURIComponent(emailParam)

      // Prüfen, ob 'step' vorhanden und nicht leer ist
      if (stepParam) {
        redirectUrl += '&step=' + encodeURIComponent(stepParam)
      }

      // Prüfen, ob 'step' vorhanden und nicht leer ist
      if (trialParam) {
        redirectUrl += '&trial=' + encodeURIComponent(trialParam)
      }
    }
    // Andernfalls prüfen, ob nur 'step' vorhanden und nicht leer ist
    else if (stepParam) {
      redirectUrl += '?step=' + encodeURIComponent(stepParam)

      if (trialParam) {
        redirectUrl += '&trial=' + encodeURIComponent(trialParam)
      }
    } else if (trialParam) {
      redirectUrl += '?trial=' + encodeURIComponent(trialParam)
    }

    // Umleiten mit der generierten URL
    redirect($routerI18n.localePath(redirectUrl, userSlug))
  },

  '/auth-password-reset/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    let userSlug = await getUserLocale(ctx)

    // Extrahieren des Sprachteils vor dem Bindestrich
    const [language, region] = userSlug.split('-')

    // Überprüfen, ob die Sprache Deutsch oder Englisch ist, sonst auf Englisch setzen
    userSlug = language === 'de' || language === 'en' ? language : 'en'

    // Zusammenfügen des neuen userSlug mit der Region
    userSlug += `-${region}`
    $vuex.commit('INIT_LOCALE_SSR', userSlug)
    const { id } = route.query

    // Basis-URL für den Redirect
    let redirectUrl = '/auth-password-reset/'

    // Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const idParam = getStringParam(id)

    // Prüfen, ob 'id' vorhanden und nicht leer ist
    if (idParam) {
      redirectUrl += '?id=' + encodeURIComponent(idParam)
    }

    // Umleiten mit der generierten URL
    redirect($routerI18n.localePath(redirectUrl, userSlug))
  },

  '/auth-verify/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    let userSlug = await getUserLocale(ctx)

    // Extrahieren des Sprachteils vor dem Bindestrich
    const [language, region] = userSlug.split('-')

    // Überprüfen, ob die Sprache Deutsch oder Englisch ist, sonst auf Englisch setzen
    userSlug = language === 'de' || language === 'en' ? language : 'en'

    // Zusammenfügen des neuen userSlug mit der Region
    userSlug += `-${region}`
    $vuex.commit('INIT_LOCALE_SSR', userSlug)
    const { vid, email } = route.query

    // Basis-URL für den Redirect
    let redirectUrl = '/auth-verify/'
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param
    }

    const emailParam = getStringParam(email)
    const vidParam = getStringParam(vid)

    // Prüfen, ob 'id' vorhanden und nicht leer ist
    if (vidParam) {
      redirectUrl += '?vid=' + encodeURIComponent(vidParam)
    }

    if (emailParam) {
      redirectUrl += '&email=' + encodeURIComponent(emailParam)
    }

    // Umleiten mit der generierten URL
    redirect($routerI18n.localePath(redirectUrl, userSlug))
  },

  '/nl-eu/sign-in/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    const { email, step } = route.query
    // Basis-URL für den Redirect
    let redirectUrl = '/en-eu/sign-in/'
    await getUserLocale(ctx)
    // Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const emailParam = getStringParam(email)
    const stepParam = getStringParam(step)

    // Prüfen, ob 'email' vorhanden und nicht leer ist
    if (emailParam) {
      redirectUrl += '?email=' + encodeURIComponent(emailParam)

      // Prüfen, ob 'step' vorhanden und nicht leer ist
      if (stepParam) {
        redirectUrl += '&step=' + encodeURIComponent(stepParam)
      }
    }
    // Andernfalls prüfen, ob nur 'step' vorhanden und nicht leer ist
    else if (stepParam) {
      redirectUrl += '?step=' + encodeURIComponent(stepParam)
    }

    // Umleiten mit der generierten URL
    redirect(redirectUrl)
  },

  '/fr-eu/sign-in/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    const { email, step } = route.query
    // Basis-URL für den Redirect
    let redirectUrl = '/en-eu/sign-in/'
    await getUserLocale(ctx)
    // Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const emailParam = getStringParam(email)
    const stepParam = getStringParam(step)

    // Prüfen, ob 'email' vorhanden und nicht leer ist
    if (emailParam) {
      redirectUrl += '?email=' + encodeURIComponent(emailParam)

      // Prüfen, ob 'step' vorhanden und nicht leer ist
      if (stepParam) {
        redirectUrl += '&step=' + encodeURIComponent(stepParam)
      }
    }
    // Andernfalls prüfen, ob nur 'step' vorhanden und nicht leer ist
    else if (stepParam) {
      redirectUrl += '?step=' + encodeURIComponent(stepParam)
    }

    // Umleiten mit der generierten URL
    redirect(redirectUrl)
  },

  '/fr-ch/sign-in/': async (ctx): Promise<void> => {
    const { redirect, $vuex, route, $routerI18n } = ctx
    const { email, step } = route.query
    // Basis-URL für den Redirect
    let redirectUrl = '/en-ch/sign-in/'
    await getUserLocale(ctx)
    // Funktion zum Überprüfen und Abrufen von String-Werten aus dem Query
    const getStringParam = (
      param: string | (string | null)[] | undefined
    ): string | undefined => {
      if (Array.isArray(param)) {
        // Nehmen Sie nur den ersten Wert, wenn es ein Array ist, und wandeln Sie 'null' in 'undefined' um
        return param[0] === null ? undefined : param[0]
      }
      return param === null ? undefined : param // Wandeln Sie 'null' in 'undefined' um
    }

    const emailParam = getStringParam(email)
    const stepParam = getStringParam(step)

    // Prüfen, ob 'email' vorhanden und nicht leer ist
    if (emailParam) {
      redirectUrl += '?email=' + encodeURIComponent(emailParam)

      // Prüfen, ob 'step' vorhanden und nicht leer ist
      if (stepParam) {
        redirectUrl += '&step=' + encodeURIComponent(stepParam)
      }
    }
    // Andernfalls prüfen, ob nur 'step' vorhanden und nicht leer ist
    else if (stepParam) {
      redirectUrl += '?step=' + encodeURIComponent(stepParam)
    }

    // Umleiten mit der generierten URL
    redirect(redirectUrl)
  },

  '/auth/callback/': async (ctx: Context) => {
    const { redirect, $isFeatureEnabled, route, $routerI18n } = ctx
    const {
      code,
      locale,
      userState,
      error,
      error_reason: errorReason,
      error_description: errorDescription,
    } = route.query
    const userSlug = await getUserLocale(ctx)
    if (!$isFeatureEnabled('video')) return
    if (error) {
      redirect(
        $routerI18n.localePath(
          {
            name: 'auth-sign-in',
            query: {
              socialAuthError: error,
              socialAuthErrorReason: errorReason,
              socialAuthErrorDescription: errorDescription,
            },
          },
          userSlug
        )
      )
    } else if (code && locale && userState && userState === 'Authenticated') {
      redirect(
        $routerI18n.localePath(
          {
            name: 'auth-sign-in',
            query: { socialAuthCode: code },
          },
          userSlug
        )
      )
    } else if (
      code &&
      locale &&
      userState &&
      userState === 'AuthenticatedNotRegistered'
    ) {
      redirect(
        $routerI18n.localePath(
          {
            name: 'auth-sign-up',
            query: {
              // socialAuthError: userState,
              socialAuthCode: code,
              //    socialAuthErrorReason: errorReason,
              // socialAuthErrorDescription: errorDescription,
            },
          },
          userSlug
        )
      )
    } else {
      //fallback redirect to home
      redirect($routerI18n.localePath('/', userSlug))
    }
  },

  '/order/': async (ctx: Context) => {
    const { redirect, $isFeatureEnabled, $routerI18n, route } = ctx
    if (!$isFeatureEnabled('video')) return
    const userSlug = await getUserLocale(ctx)
    redirect(
      $routerI18n.localePath(
        {
          name: 'order-confirmation',
          query: {
            email: route.query.email,
            order: route.query.order,
            didBuyTrampoline: route.query.didBuyTrampoline,
          },
        },
        userSlug
      )
    )
  },

  '/account/': async (ctx: Context) => {
    const { redirect, $isFeatureEnabled, $routerI18n, $vuex, route } = ctx
    if (!$isFeatureEnabled('video')) return
    if (!$vuex.state.auth.isLoading)
      await $vuex.dispatch('auth/refresh', {
        refreshToken: $vuex.state.auth.refreshToken,
      })
    let didRefresh = false
    $vuex.watch(
      (state) => state.auth.isLoading,
      (newVal, oldVal) => {
        if (oldVal && !newVal && !didRefresh) {
          $vuex.dispatch('auth/refresh', {
            refreshToken: $vuex.state.auth.refreshToken,
          })
          didRefresh = true
          if (route.query.state === 'succeeded')
            $vuex.dispatch('auth/subscriptionCompleted')
        }
      }
    )
    const userSlug = await getVideoLocale(await getUserLocale(ctx))
    redirect(
      $routerI18n.localePath(
        {
          name: 'video-account',
        },
        userSlug
      )
    )
  },
} as UrlPageMap
